import request from '../../request'
import qs from 'qs'

// 试卷管理

// 分页模糊查询
export const AueryEduTestPaperAll = (p) => {
    return request.get(`eduTestPaper/queryEduTestPaperAll?${qs.stringify(p)}`).then(res => res.data);
}
// 添加试卷
export const AddEduTestPaper = (data) => {
    return request.post(`eduTestPaper/addEduTestPaper`, data).then(res => res.data);
}
// 编辑试卷
export const UpdateEduTestPaperById = (data) => {
    return request.post(`eduTestPaper/updateEduTestPaperById`, data).then(res => res.data);
}
//  预览
export const ExamPaperPreview = (p) => {
    return request.get(`TeaCourseStudentExam/examPaperPreview?${qs.stringify(p)}`).then(res => res.data);
}